function formatNumberWithCheck(input) {
    let num;
    if (typeof input === 'number') {
      num = input;
    } else if (!isNaN(parseFloat(input)) && isFinite(input)) {
      num = parseFloat(input);
    } else {
      return '-';
    }
    let parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.length > 1? parts.join('.') : parts[0];
  }

export {
    formatNumberWithCheck,
}