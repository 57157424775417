import { ApiV3DeviceMapDeviceList2DeviceMapVersion } from '@/constant'

const api_v3_deviceMap_deviceList2_deviceMap = {
  version: ApiV3DeviceMapDeviceList2DeviceMapVersion,
  total: 0,
  list: [],
  data: {},
}
try {
  let deviceMap = JSON.parse(localStorage.getItem('api_v3_deviceMap_deviceList2_deviceMap'))
  if (deviceMap && deviceMap.version === api_v3_deviceMap_deviceList2_deviceMap.version) {
    api_v3_deviceMap_deviceList2_deviceMap = deviceMap
  }
} catch (e) {}

export default {
  state: {
    map: null,
    mapPlugins: {
      Scale: null,
      ToolBar: null,
      MouseTool: null,
      PolygonEditor: null,
    },
    device: {
      deviceMap: api_v3_deviceMap_deviceList2_deviceMap.data,
      deviceList: api_v3_deviceMap_deviceList2_deviceMap.list,
      total: api_v3_deviceMap_deviceList2_deviceMap.total,
      selectedUid: '',
    },
    collection: {
      deviceMap: {},
      deviceList: [],
      dListShow: false,
      activeDevice: 0,
      setFitView: true,
      collection: [],
      collectionAll: [],
      collectionMap: {},
      collectionInfo: {},
      listShow: false,
    },
    box: {
      activeDevice: 0,
    },
    mapLoaderShow: false,
  },
}
