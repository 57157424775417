var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    _vm._g(
      _vm._b({ staticClass: "ui-table" }, "el-table", { ..._vm.$attrs }, false),
      _vm.$listeners
    ),
    [_vm._t("default"), _c("template", { slot: "empty" }, [_c("el-empty")], 1)],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }