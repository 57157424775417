var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table-column",
    _vm._g(
      _vm._b(
        {
          staticClass: "ui-table-column",
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.$scopedSlots.default
                      ? _vm._t("default", null, null, scope)
                      : scope.column.property
                      ? [
                          _vm._v(
                            " " + _vm._s(scope.row[scope.column.property]) + " "
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        "el-table-column",
        { ..._vm.$attrs },
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }