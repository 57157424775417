var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    _vm._g(
      _vm._b(
        { staticClass: "ui-popover" },
        "el-popover",
        { ..._vm.$attrs },
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.$slots.reference ? [_vm._t("reference")] : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }