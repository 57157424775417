import { getToken } from '@/utils/auth'
import defer from '@/utils/defer'

let $service = {}
let maxReconnectAttempts = 2
let fnList = []
let keepAliveTimeout = 15000
let timer = {
  keepAlive: null,
  stopClose: null,
  checkSocket: null,
}
let stopClose = false
let sendList = []

function resetKeepAlive () {
  if (timer.keepAlive) {
    window.clearInterval(timer.keepAlive)
    timer.keepAlive = null
  }
}

async function query ({ method, type, data }) {
  let token = await getToken()
  if (!token) {
    maxReconnectAttempts = 0
    $app.$socket.close()
    $app.loginInvalid(3)
    return
  }
  let transaction = uuid()
  let body = type ? Object.assign({}, data, { type }) : data
  let query = { method, transaction, body }

  $service[transaction] = defer()
  $service[transaction].query = query

  let sendMsg = { query, transaction }
  if ($app.$socket.readyState !== 1) {
    sendList.push(sendMsg)
    stop('send', true)
  } else {
    send(sendMsg)
  }

  return $service[transaction].promise
}

function send ({ query, transaction }) {
  $app.$socket.send(JSON.stringify(query))
  setTimeout(() => {
    if ($service[transaction]) {
      $service[transaction].reject('timeout')
      delete $service[transaction]
    }
  }, 50000)
}

function start (userInfo) {
  resetKeepAlive()
  timer.keepAlive = window.setInterval(() => {
    if ($app.$socket.readyState === 1) {
      let method = 'KeepAlive'
      let type = ''
      let data = {
        fromUid: userInfo.userId,
      }
      $app.$socket.query({ method, type, data })
    } else {
      stop('KeepAlive')
    }
  }, keepAliveTimeout)
}

function resetStopClose () {
  stopClose = false
  if (timer.stopClose) {
    clearTimeout(timer.stopClose)
    timer.stopClose = null
  }
  timer.checkSocket = setTimeout(() => {
    if (!$app.$socket || $app.$socket.readyState !== 1) {
      stop('re-connect', true)
    }
  }, keepAliveTimeout)
}

function startStopClose () {
  stopClose = true
  if (timer.checkSocket) {
    clearTimeout(timer.checkSocket)
    timer.checkSocket = null
  }
  timer.stopClose = setTimeout(() => {
    resetStopClose()
  }, 3500)
}

function firErrorCheck () {
  // 服务器连接
  $app.$message.error('服务器连接异常')
  console.debug('服务器连接异常')
  $app.logout()
}

export async function stop (from, isReset = false) {
  console.debug('$app.$socket.stop', from)
  resetKeepAlive()
  let token = await getToken()
  let stopReturn = stopClose || !token
  if (stopReturn) {
    console.debug('$app.$socket.stopReturn')
    return
  }
  startStopClose()
  if (isReset) {
    maxReconnectAttempts = 1
  }
  $app.$socket = await newWebSocket($app, true)
  $app.$socket.readyConnected.promise.then(() => {
    resetStopClose()
    if (sendList.length) {
      sendList.forEach(sendMsg => {
        send(sendMsg)
      })
      sendList = []
    }
  })
  return $app.$socket
}

function uuid () {
  function S4 () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
}

export default async function newWebSocket ($app, isReconnect = false) {
  if (!window.WebSocket) {
    console.debug('not support websocket')
  }
  maxReconnectAttempts--
  if (maxReconnectAttempts < 0) {
    console.debug('重连超出次数')
  }

  if (!window.WebSocket || maxReconnectAttempts < 0) {
    let fn = function () {}
    return {
      send: fn,
      query: query,
      onmessage: fn,
      addEventListener: fn,
      removeEventListener: fn,
      readyConnected: {
        promise: Promise.resolve(),
      },
      stop,
      close: fn,
      add: fn,
    }
  }

  let websocket = null

  await $app.userReady

  console.debug('new.webSocket: ' + $app.VUE_APP_BASE_SOCKET)

  try {
    websocket = new WebSocket($app.VUE_APP_BASE_SOCKET)
  } catch (error) {
    $app.$message.error('服务器连接异常')
    return
  }

  websocket.stop = stop

  websocket.readyConnected = defer()

  if (!isReconnect) {
    websocket.addEventListener('error', firErrorCheck)
  }

  websocket.onopen = async function (e) {
    let userInfo = await $app.userReady
    let method = 'Connection'
    let type = ''
    let data = {
      token: await getToken(),
      uid: userInfo.userId,
    }
    let isOk = false
    let sTimer = 0
    $app.$socket.query({ method, type, data }).then(res => {
      if (res.code === 200 && res.data) {
        if (res.data.message === '用户未登录,服务器已断开连接') {
          $app.logout()
          clearTimeout(sTimer)
          return
        }
        isOk = true
        start(userInfo)
        $app.$socket.readyConnected.resolve()
        console.debug('$app.$socket.connected')
        websocket.removeEventListener('error', firErrorCheck)
      }
    })
    sTimer = setTimeout(() => {
      if (!isOk) {
        $app.$message.error('服务器连接超时')
        console.debug('服务器连接超时')
        $app.logout()
      }
    }, 2500)
  }

  websocket.restart = false

  // 连接发生错误，连接错误时会继续尝试发起连接
  websocket.onerror = async function () {
    console.debug('$app.$socket.error')
    await $app.userReady
    if (!websocket.restart) {
      websocket.restart = true
      stop('error')
    }
  }

  // 接受到服务端关闭连接时的回调方法
  websocket.onclose = async function () {
    console.debug('$app.$socket.close')
    await $app.userReady
    if (!websocket.restart) {
      websocket.restart = true
      stop('close')
    }
  }

  websocket.query = query

  websocket.add = function (fn) {
    fnList.push(fn)
  }

  websocket.remove = function (fn) {
    let index = fnList.findIndex(e => {
      return e === fn
    })
    if (index !== -1) {
      fnList.splice(index, 1)
    }
  }

  websocket.getFnList = function () {
    return fnList
  }

  websocket.addEventListener('message', function (e) {
    try {
      let msg = JSON.parse(e.data)
      if (msg) {
        if ($service[msg.transaction]) {
          if (msg.code === 200) {
            msg.query = $service[msg.transaction].query
            $service[msg.transaction].resolve(msg)
          } else {
            $service[msg.transaction].reject(msg)
          }
        }

        fnList.forEach(fn => {
          fn(msg)
        })
      }
    } catch (error) {}
  })

  return websocket
}
