var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g(
      _vm._b(
        {
          ref: "font",
          staticClass: "zoom-font",
          class: "size" + _vm.size,
          style: {
            transform: `scale(${_vm.zoom})`,
          },
        },
        "div",
        { ..._vm.$attrs },
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }