var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex wrapper" },
    [
      _c(
        "el-input",
        _vm._g(
          _vm._b(
            { ref: "input", staticClass: "ui-input shrink1" },
            "el-input",
            { ..._vm.$attrs },
            false
          ),
          _vm.$listeners
        ),
        [
          _vm._t("default"),
          _vm.$slots.suffix
            ? _c("template", { slot: "suffix" }, [_vm._t("suffix")], 2)
            : _vm._e(),
        ],
        2
      ),
      _vm.$slots.tail
        ? _c("div", { staticClass: "tail shrink0" }, [_vm._t("tail")], 2)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }