const state = {
  lastUser: {},
  userInfo: {},
  map: {},
}

const mutations = {}

export default {
  namespaced: true,
  state,
  mutations,
}
