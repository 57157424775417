var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        { staticClass: "ui-dialog" },
        "el-dialog",
        { ..._vm.$attrs },
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.$slots.title
        ? _c("template", { slot: "title" }, [_vm._t("title")], 2)
        : _vm._e(),
      _vm._t("default"),
      _vm.$slots.footer
        ? _c("template", { slot: "footer" }, [_vm._t("footer")], 2)
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }