var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-button",
    _vm._g(
      _vm._b(
        {
          staticClass: "ui-button",
          class: {
            "s--6": _vm.size === -6,
            "s--4": _vm.size === -4,
            "s--2": _vm.size === -2,
            "s--1": _vm.size === -1,
            "s-1": _vm.size === 1,
            "s-2": _vm.size === 2,
          },
        },
        "el-button",
        { ..._vm.$attrs },
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }